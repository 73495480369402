export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const GET_USER_DATA_REQUEST = "GET_USER_DATA_REQUEST";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_FAILURE";
export const SET_USER_AUTH_STATUS = "SET_USER_AUTH_STATUS";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const SEND_RESET_EMAIL_REQUEST = "SEND_RESET_EMAIL_REQUEST";
export const SEND_RESET_EMAIL_SUCCESS = "SEND_RESET_EMAIL_SUCCESS";
export const SEND_RESET_EMAIL_FAILURE = "SEND_RESET_EMAIL_FAILURE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";
export const CLEAR_STATE = "CLEAR_STATE";

export const loginRequest = (data) => ({
  type: LOGIN_REQUEST,
  payload: data,
});

export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});

export const loginFailure = (data) => ({
  type: LOGIN_FAILURE,
  payload: data,
});

export const registerRequest = (data) => ({
  type: REGISTER_REQUEST,
  payload: data,
});

export const registerSuccess = (data) => ({
  type: REGISTER_SUCCESS,
  payload: data,
});

export const registerFailure = (data) => ({
  type: REGISTER_FAILURE,
  payload: data,
});

export const sendResetEmailRequest = (data) => ({
  type: SEND_RESET_EMAIL_REQUEST,
  payload: data,
});

export const sendResetEmailSuccess = (data) => ({
  type: SEND_RESET_EMAIL_SUCCESS,
  payload: data,
});

export const sendResetEmailFailure = (data) => ({
  type: SEND_RESET_EMAIL_FAILURE,
  payload: data,
});

export const resetPasswordRequest = (uuid, newPassword) => ({
  type: RESET_PASSWORD_REQUEST,
  payload: { uuid, newPassword },
});

export const resetPasswordSuccess = (data) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: data,
});

export const resetPasswordFailure = (data) => ({
  type: RESET_PASSWORD_FAILURE,
  payload: data,
});

export const getUserDataRequest = (id, token) => ({
  type: GET_USER_DATA_REQUEST,
  payload: { id, token },
});

export const getUserDataSuccess = (data) => ({
  type: GET_USER_DATA_SUCCESS,
  payload: data,
});

export const getUserDataFailure = (data) => ({
  type: GET_USER_DATA_FAILURE,
  payload: data,
});

export const refreshTokenRequest = (refreshToken) => ({
  type: REFRESH_TOKEN_REQUEST,
  payload: refreshToken,
});

export const refreshTokenSuccess = (data) => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: data,
});

export const refreshTokenFailure = (data) => ({
  type: REFRESH_TOKEN_FAILURE,
  payload: data,
});

export const setUserAuthStatus = (authStatus) => ({
  type: SET_USER_AUTH_STATUS,
  payload: authStatus,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
