import { of, from } from "rxjs";
import { catchError, switchMap, map } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";

import * as actions from "./actions";

export const cmsEpicFactory = (cmsService) => {
  const loadCmsEpic = (actions$) =>
    actions$.pipe(
      ofType(actions.CMS_REQUEST),
      switchMap(() =>
        from(cmsService.getContent()).pipe(
          map(actions.cmsRequestSuccess),
          catchError((error) => of(actions.cmsRequestFailure(error)))
        )
      )
    );

  return combineEpics(loadCmsEpic);
};
