import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

import { Wrapper } from "../../components/auth/Form";
import {
  SuccessInfoWrapper,
  Title,
  AddInfo,
} from "../../components/InformationComponents";
import { mediaQueries } from "../../styles/variable";

const FormWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  width: 90%;
  ${mediaQueries.lg} {
    width: 22rem;
  }
`;

const SuccessContainer = ({ t }) => (
  <Wrapper>
    <FormWrapper>
      <SuccessInfoWrapper>
        <Title>{t("activate email 1")}</Title>
        <AddInfo>{t("activate email 2")}</AddInfo>
      </SuccessInfoWrapper>
    </FormWrapper>
  </Wrapper>
);

export const Success = withTranslation("auth")(SuccessContainer);
