import styled, { css } from "styled-components";
import Markdown from "react-markdown";

import {
  colors,
  colorsRGBA,
  mediaQueries,
  fontFamily,
  fontSize,
  linearGradient,
} from "../styles/variable";
import { subPageBackground } from "../statics/configs/images";

export const Scrollbar = css`
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.codGray};
    margin: 0.5rem 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colorsRGBA.emperor70};
    border-radius: 20px;
    border: 3px solid ${colors.transparent};
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${colorsRGBA.experor100};
    border: 3px solid ${colors.transparent};
    background-clip: content-box;
  }
`;

export const PageWrapper = styled.div`
  background-color: ${colors.black};
  background-repeat: no-repeat;
  background-image: ${linearGradient.subPageBackground},
    url(${subPageBackground});
  background-size: contain;
  margin: 0 auto;

  min-height: calc(100vh - 15.6rem);

  ${mediaQueries.lg} {
    min-height: calc(100vh - 18.1rem);
  }
`;

export const ContentWrapper = styled(Markdown)`
  font-family: ${fontFamily.spartan};
  color: ${colors.white};
  font-size: ${fontSize.normalExtended};
  line-height: 1.75rem;
  width: 90vw;
  margin: 0 auto;

  ${mediaQueries.md} {
    width: 44rem;
    margin: 10rem auto;
    font-size: ${fontSize.normal};
  }

  ${mediaQueries.xl} {
    width: 51rem;
    margin: 10rem auto;
    font-size: ${fontSize.normal};
  }
`;

export const MainSectionWrapper = styled.div`
  padding: 1rem;
`;
