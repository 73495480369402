import * as actions from "./actions";

const userReducerInitialState = {
  errorMessage: "",
  errorDetail: "",
  access: "",
  refresh: "",
  userAuthStatus: false,
  isLoaded: false,
  isFetchFailed: false,
};

export const userReducer = (state = userReducerInitialState, action) => {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
    case actions.REGISTER_REQUEST:
    case actions.SEND_RESET_EMAIL_REQUEST:
    case actions.RESET_PASSWORD_REQUEST:
    case actions.REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        ...action.payload,
      };

    case actions.GET_USER_DATA_REQUEST:
      return {
        ...state,
        ...action.payload,
        isLoaded: true,
        userAuthStatus: true,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        userAuthStatus: true,
        isLoaded: true,
      };
    case actions.LOGIN_FAILURE:
      return {
        ...state,
        errorMessage: action.payload.message,
        errorDetail: action.payload.detail,
      };
    case actions.REGISTER_FAILURE:
      return {
        ...state,
        emailError: action.payload.email,
        phoneNumberError: action.payload.phoneNumber,
        message: action.payload.detail,
      };
    case actions.REGISTER_SUCCESS:
      return {
        ...state,
        isRegisterComplete: true,
      };

    case actions.SEND_RESET_EMAIL_SUCCESS:
      return {
        ...state,
        isSendResetEmailCompleted: true,
      };

    case actions.SEND_RESET_EMAIL_FAILURE:
      return {
        ...state,
        sendResetEmailError: action.payload.email,
        sendResetEmailMessage: action.payload.message,
      };

    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPasswordCompleted: true,
      };

    case actions.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordError: action.payload.password,
        resetPasswordMessage: action.payload.message,
      };
    case actions.REFRESH_TOKEN_SUCCESS:
    case actions.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        userAuthStatus: true,
        isLoaded: true,
      };

    case actions.GET_USER_DATA_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoaded: true,
        isFetchFailed: true,
      };
    case actions.REFRESH_TOKEN_FAILURE:
      return { userAuthStatus: false, isLoaded: true };
    case actions.SET_USER_AUTH_STATUS:
      return { ...state, userAuthStatus: action.payload, isLoaded: true };
    case actions.CLEAR_STATE:
      return (state = {});
    default:
      return state;
  }
};
