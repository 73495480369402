import styled, { css } from "styled-components";
import { Form, Field } from "formik";

import {
  fontSize,
  fontWeight,
  colors,
  mediaQueries,
  fontFamily,
} from "../../styles/variable";
import { Scrollbar } from "../common";

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 2rem;
  width: 280px;
  left: calc(50% - 140px);
  height: 90vh;
  overflow-y: scroll;
  ${Scrollbar}
  margin: 0 auto;
  background-color: ${colors.codGray};
  color: ${colors.white};
  max-height: 50rem;

  ${mediaQueries.sm} {
    width: 24rem;
    left: calc(50% - 12rem);
  }

  ${mediaQueries.lg} {
    width: 29.4rem;
    height: 88vh;
    left: calc(50% - 14rem);
    top: calc(50% - 44vh);
  }
`;

export const FormWrapper = styled(Form)`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  width: 85%;
`;

export const InputSection = styled.div`
  width: 100%;
`;

export const InputField = styled.input`
  border-radius: 1px;
  margin: 0.4rem 0;
  padding: 0.3rem;
  height: 1.5rem;
  border-radius: 1px;
  border: none;
  width: 96%;
  font-family: ${fontFamily.spartan};
  font-size: ${fontSize.small};
  background-color: ${colors.background};
  color: ${colors.white};

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 100px rgb(80, 80, 80) inset;
    -webkit-text-fill-color: ${colors.white};
  }

  &::placeholder {
    color: ${colors.white};
    opacity: 1;
  }

  ${mediaQueries.lg} {
    height: 3.25rem;
    padding-left: 1.5rem;
    width: 93%;
    margin: ${({ message }) => (message ? "0 0 0.5rem" : "0 0 1.5rem")};
  }
`;

export const ErrorMessage = styled.p`
  color: ${colors.red};
  margin: 0.1rem;
  font-size: ${fontSize.xSmall};

  ${mediaQueries.lg} {
    margin-bottom: 0.5rem;
    font-weight: ${fontWeight.semiBold};
  }
`;

export const SubmitButton = styled.button`
  border: none;
  padding: 0.5rem;
  color: ${colors.white};
  font-family: ${fontFamily.spartan};
  font-weight: ${fontWeight.semiBold};
  font-size: ${fontSize.normalExtended};
  background-color: ${({ themeColor }) => themeColor};
  min-height: 2.5rem;
  cursor: pointer;

  ${mediaQueries.lg} {
    height: 3rem;
    margin-top: 0.75rem;
    font-size: ${fontSize.normalExtended};
    font-weight: ${fontWeight.extraBold};
  }
`;

export const TitleWrapper = styled.p`
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.extraBold};
  ${mediaQueries.lg} {
    font-size: ${fontSize.extraLarge};
    margin: 3.6rem 0 1.5rem;
  }
`;
export const RegulationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;

  ${mediaQueries.sm} {
    flex-direction: row;
  }
  ${mediaQueries.lg} {
    margin: 0;
    margin-bottom: 0;
    height: 1.6rem;
  }
`;

export const ParagraphWrapper = styled.p`
  margin: 0 0.2rem;
  color: ${colors.white};
  font-size: ${fontSize.small};
  line-height: 1.5rem;
  font-weight: ${fontWeight.semiBold};
`;

export const HaveAccountInfo = styled.div`
  width: 12rem;

  margin: 0 0 1.5rem;
  font-weight: ${fontWeight.semiBold};
  font-size: ${fontSize.small};

  ${mediaQueries.sm} {
    width: fit-content;
  }
`;

export const LinkSectionWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;

  ${mediaQueries.lg} {
    flex-direction: row;
    margin: 1.5rem 0;
  }
`;

export const SingleLinkSectionWrapper = styled.div`
  margin: 1rem auto;
`;

export const LinkWrapper = styled.div`
  text-decoration: underline;
  color: ${colors.white};
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.semiBold};
  cursor: pointer;
  margin-left: 0.25rem;
`;

export const LinkToResetPassword = styled.p`
  font-size: ${fontSize.small};
  cursor: pointer;
  text-decoration: underline;
  font-weight: ${fontWeight.semiBold};
`;

export const AccountActivationInfo = styled.div`
  font-size: ${fontSize.base};
  font-weight: ${fontWeight.semiBold};
  margin: 0.2rem 0 0.5rem;
  line-height: 1.5rem;
`;

export const Checkbox = css`
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  width: 18px;
  height: 18px;
  color: black;
  border: 1px solid white;
  appearance: none;
  outline: 0;
  cursor: pointer;
  flex-shrink: 0;

  &::before {
    display: flex;
    content: "";
    width: 4px;
    height: 10px;
    border: 2px solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }

  &:checked {
    color: white;
    border-color: white;
    &::before {
      opacity: 1;
    }
  }
`;

export const CheckboxWrapper = styled(Field)`
  ${Checkbox}
`;

export const RegulationLink = styled.a`
  margin: 0 0.2rem;
  color: ${colors.white};
  font-size: ${(props) => (props.fontSize ? props.fontSize : fontSize.small)};
  line-height: 1.5rem;
  font-weight: ${fontWeight.semiBold};
`;

export const CheckboxSectiom = styled.div`
  display: flex;
`;

export const InfoWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  width: 90%;
  ${mediaQueries.lg} {
    width: 22rem;
  }
`;
