import React from "react";
import { useHistory, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  clearState,
  setUserAuthStatus,
} from "../../core/authentication/actions";
import { LanguageInfo } from "./LanguageInfo";
import {
  MobileWrapper,
  MobileLogoWrapper,
  Hamburger,
  Menu,
  LiveStreamLink,
  Row,
  MobileLanguageWrapper,
} from "./HeaderComponents";
import { cookieService } from "../../services";
import { useOutsideCloser } from "../../hooks/useOutsideCloser";
import { logoIcon } from "../../statics/configs/icons";
import { routes } from "../../statics/routes";

const MobileHeaderContainer = ({
  t,
  setIsOpen,
  isOpen,
  isCorrectPath,
  scroll,
  setUserAuthStatus,
  isAuth,
  clearState,
}) => {
  const [isActive, setIsActive] = React.useState(false);
  const history = useHistory();

  const handleClick = () => {
    if (isAuth) {
      history.push(routes.stream);
      setIsActive(false);
    } else {
      setIsOpen(!isOpen);
      setIsActive(false);
    }
  };

  const handleLogout = () => {
    if (isAuth) {
      setUserAuthStatus(false);
      setIsActive(false);
      setIsOpen(false);
      cookieService.removeItem("token");
      cookieService.removeItem("refresh");
      clearState();
      history.push(routes.main);
    } else {
      setIsOpen(true);
      setIsActive(false);
    }
  };

  const ref = React.useRef();
  useOutsideCloser(ref, () => setIsActive(false));

  const handleClickOnHomePageButton = () => {
    history.push(routes.main);
    setIsActive(false);
  };

  return (
    <MobileWrapper ref={ref} isActive={isActive} isCorrectPath={isCorrectPath}>
      <Link to={routes.main}>
        <MobileLogoWrapper src={logoIcon}></MobileLogoWrapper>
      </Link>
      <MobileLanguageWrapper>
        <LanguageInfo />
        <Hamburger isActive={isActive} onClick={() => setIsActive(!isActive)}>
          <div />
        </Hamburger>
      </MobileLanguageWrapper>
      <Menu isActive={isActive}>
        <Row onClick={handleClickOnHomePageButton}>{t("event.home page")}</Row>
        <LiveStreamLink onClick={handleClick}>
          {t("event.button")}
        </LiveStreamLink>
        <Row onClick={scroll}>{t("partners")}</Row>
        <Row onClick={handleLogout}>{isAuth ? t("logout") : t("login")}</Row>
      </Menu>
    </MobileWrapper>
  );
};

const mapStateToProps = (state) => ({
  isAuth: state.user.userAuthStatus,
});

const mapDispatchToProps = {
  clearState,
  setUserAuthStatus,
};

export const MobileHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("home")(MobileHeaderContainer));
