import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";

import { Wrapper, SubmitButton } from "./Form";
import {
  fontSize,
  fontWeight,
  colors,
  mediaQueries,
} from "../../styles/variable";
import { themeColorSelector } from "../../core/cms/selectors";

const FormWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  width: 90%;
  ${mediaQueries.lg} {
    width: 22rem;
  }
`;

const TitleWrapper = styled.div`
  margin: 9.9rem 0 2rem;
  text-align: center;
  font-weight: ${fontWeight.semiBold};
  font-size: ${fontSize.normal};
  line-height: 1.8rem;
`;

const HaveAccount = styled.div`
  font-weight: ${fontWeight.semiBold};
  font-size: ${fontSize.normal};
  line-height: 1.8rem;
  text-align: center;
  margin: 4rem 0 2rem;
`;

const LoginButton = styled(SubmitButton)`
  background-color: ${colors.transparent};
  border: 2px solid ${({ themeColor }) => themeColor};
  color: ${({ themeColor }) => themeColor};
`;

const InfoFormContainer = ({
  t,
  setIsLoginActive,
  setIsInfoModalActive,
  cms,
}) => {
  const handleRegister = () => {
    setIsInfoModalActive(false);
    setIsLoginActive(false);
  };

  const handleLogin = () => {
    setIsInfoModalActive(false);
    setIsLoginActive(true);
  };

  return (
    <Wrapper>
      <FormWrapper>
        <TitleWrapper>{t("info modal.buy access condition")}</TitleWrapper>
        <SubmitButton
          onClick={handleRegister}
          themeColor={themeColorSelector(cms)}
        >
          {t("info modal.register")}
        </SubmitButton>
        <HaveAccount>{t("info modal.have account")}</HaveAccount>
        <LoginButton onClick={handleLogin} themeColor={themeColorSelector(cms)}>
          {t("info modal.login")}
        </LoginButton>
      </FormWrapper>
    </Wrapper>
  );
};

const mapStateToProps = ({ cms }) => ({
  cms,
});

export const InfoForm = connect(mapStateToProps)(
  withTranslation("auth")(InfoFormContainer)
);
