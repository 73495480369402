import React from "react";
import { withTranslation } from "react-i18next";
import { Formik, Field } from "formik";
import { connect } from "react-redux";

import { sendResetEmailRequest } from "../../core/authentication/actions";
import {
  Wrapper,
  TitleWrapper,
  ErrorMessage,
  FormWrapper,
  SubmitButton,
  InfoWrapper,
} from "../auth/Form";
import { Input } from "../auth/Input";
import { themeColorSelector } from "../../core/cms/selectors";
import {
  Title,
  AddInfo,
  SuccessInfoWrapper,
  BackArrowWrapper,
} from "../../components/InformationComponents";
import { leftArrow } from "../../statics/configs/icons";
import { resetMailSchema } from "./schema";

const initialValues = {
  email: "",
};

const input = [
  { type: "email", value: "email", name: "email", maxLength: 254 },
];

const SendEmailFormContainer = ({
  t,
  setIsResetModalOpen,
  i18n,
  sendResetEmailRequest,
  emailError,
  message,
  isSendResetEmailCompleted,
  cms,
}) => {
  const [error, setError] = React.useState("");

  const submitEmail = ({ email }) => {
    const resetEmail = { email: email.toLowerCase() };
    sendResetEmailRequest(resetEmail);
  };

  React.useEffect(() => {
    emailError && setError(t("reset.email nok"));
  }, [emailError]);

  return isSendResetEmailCompleted ? (
    <Wrapper>
      <InfoWrapper>
        <SuccessInfoWrapper>
          <Title>{t("reset.email ok 1")}</Title>
          <AddInfo>{t("reset.email ok 2")}</AddInfo>
        </SuccessInfoWrapper>
      </InfoWrapper>
    </Wrapper>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={resetMailSchema({ t })}
      onSubmit={(values) => submitEmail(values)}
    >
      {({ handleSubmit }) => (
        <Wrapper>
          <FormWrapper onSubmit={handleSubmit}>
            <TitleWrapper>{t("reset.title")}</TitleWrapper>
            {input.map((item) => (
              <Field
                key={item.value}
                name={item.value}
                type={item.type}
                placeholder={t(`user.${item.name}`)}
                component={Input}
              />
            ))}
            <SubmitButton type="submit" themeColor={themeColorSelector(cms)}>
              {t("reset.submit email")}
            </SubmitButton>
            <ErrorMessage>{error}</ErrorMessage>
            <ErrorMessage>{message}</ErrorMessage>
            <BackArrowWrapper
              alt="back arrow"
              src={leftArrow}
              onClick={() => setIsResetModalOpen(false)}
            />
          </FormWrapper>
        </Wrapper>
      )}
    </Formik>
  );
};

const mapStateToProps = ({
  user: {
    sendResetEmailError,
    sendResetEmailMessage,
    isSendResetEmailCompleted,
  },
  cms,
}) => ({
  emailError: sendResetEmailError,
  message: sendResetEmailMessage,
  isSendResetEmailCompleted,
  cms,
});

const mapDispatchToProps = {
  sendResetEmailRequest,
};

export const SendEmailForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("auth")(SendEmailFormContainer));
