import React from "react";

import { InputField, ErrorMessage, InputSection } from "./Form";

export const Input = ({
  field: { onChange, value, ...field },
  form: { errors, touched },
  placeholder,
  key,
  type,
  maxLength,
  emailMessage,
}) => {
  const message = touched[field.name] && errors[field.name];
  return (
    <InputSection key={key}>
      <InputField
        name={field.name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...field}
        maxLength={maxLength}
        message={message}
      />
      {message && <ErrorMessage>{message}</ErrorMessage>}

      {emailMessage && field.name === "email" && (
        <ErrorMessage>{emailMessage}</ErrorMessage>
      )}
    </InputSection>
  );
};
