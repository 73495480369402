import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import {
  getUserDataRequest,
  refreshTokenRequest,
  setUserAuthStatus,
} from "../core/authentication/actions";
import { cmsRequest } from "../core/cms/actions";
import { routesList } from "./routesList";
import Loader from "../pages/loader";
import { routes } from "../statics/routes";
import { decodeToken } from "../helper/decodeToken";
import { cookieService } from "../services";
import { Layout } from "../components/Layout";
import { LoaderComponent } from "../components/Loader";

const RoutesContainer = ({
  getUserDataRequest,
  isLoaded,
  userAuthStatus,
  isFetchFailed,
  refreshTokenRequest,
  setUserAuthStatus,
  cmsRequest,
  cms: { data, error },
}) => {
  React.useEffect(() => {
    cmsRequest();
  }, [cmsRequest]);

  React.useEffect(() => {
    const token = cookieService.getItem("token");
    if (!token) {
      setUserAuthStatus(false);
      return;
    }
    getUserDataRequest(decodeToken(token), token);
  }, []);

  React.useEffect(() => {
    if (isFetchFailed) {
      const refreshToken = cookieService.getItem("refresh");
      if (!refreshToken) {
        cookieService.removeItem("token");
        setUserAuthStatus(false);
        return;
      }

      const refresh = { refresh: refreshToken };
      refreshTokenRequest(refresh);
    }
  }, [isFetchFailed]);

  return (
    <BrowserRouter>
      <Layout>
        <React.Suspense fallback={<LoaderComponent />}>
          <Switch>
            {(!data || error) && <Loader />}
            {routesList.map((route) =>
              route.isAuthenticated ? (
                <Route
                  exact={route.exact}
                  path={route.path}
                  component={route.component}
                  key={route.component}
                />
              ) : (
                <Route
                  exact={route.exact}
                  path={route.path}
                  render={() => {
                    if (isLoaded) {
                      return userAuthStatus ? (
                        <route.component />
                      ) : (
                        <Redirect to={routes.main} />
                      );
                    }
                    return <Loader />;
                  }}
                  key={route.component}
                />
              )
            )}
          </Switch>
        </React.Suspense>
      </Layout>
    </BrowserRouter>
  );
};

const mapStateToProps = ({
  user: { userAuthStatus, isLoaded, isFetchFailed },
  cms,
}) => ({
  userAuthStatus,
  isLoaded,
  isFetchFailed,
  cms,
});

const mapDispatchToProps = {
  getUserDataRequest,
  refreshTokenRequest,
  setUserAuthStatus,
  cmsRequest,
};

export const Routes = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutesContainer);
