import React from "react";

export const routesList = [
  {
    exact: true,
    isAuthenticated: true,
    path: "/contact",
    component: React.lazy(() => import("../pages/contact.js")),
  },
  {
    exact: true,
    isAuthenticated: true,
    path: "/",
    component: React.lazy(() => import("../pages/index.js")),
  },
  {
    exact: true,
    isAuthenticated: true,
    path: "/terms-of-use",
    component: React.lazy(() => import("../pages/termsOfUse.js")),
  },
  {
    exact: true,
    isAuthenticated: true,
    path: "/faq",
    component: React.lazy(() => import("../pages/faq.js")),
  },
  {
    exact: true,
    isAuthenticated: true,
    path: "/password-reset/:id",
    component: React.lazy(() => import("../pages/resetPassword")),
  },
  {
    exact: true,
    isAuthenticated: true,
    path: "/invitation/:id",
    component: React.lazy(() => import("../pages/invitation")),
  },
  {
    exact: true,
    isAuthenticated: true,
    path: "/payment-success",
    component: React.lazy(() => import("../pages/paymentSuccess")),
  },
  {
    exact: false,
    isAuthenticated: true,
    path: "/return",
    component: React.lazy(() => import("../pages/blueMediaReturn")),
  },
  {
    exact: true,
    isAuthenticated: true,
    path: "/register-invitation/:invitationCode",
    component: React.lazy(() => import("../pages/registerInvitation")),
  },
  {
    exact: true,
    isAuthenticated: false,
    path: "/live-stream",
    component: React.lazy(() => import("../pages/liveStream")),
  },
  {
    exact: true,
    isAuthenticated: false,
    path: "/admin/input",
    component: React.lazy(() => import("../pages/admin")),
  },
  {
    exact: false,
    isAuthenticated: true,
    component: React.lazy(() => import("../pages/error")),
  },
];
