export class HttpServices {
  constructor(cookieService) {
    this.cookieService = cookieService;
  }

  get(path, tokenValidation) {
    return this.makeRequest("GET", path, null, tokenValidation);
  }

  post(path, body, tokenValidation) {
    return this.makeRequest("POST", path, body, tokenValidation);
  }

  delete(path, tokenValidation) {
    return this.makeRequest("DELETE", path, null, tokenValidation);
  }

  patch(path, body, tokenValidation) {
    return this.makeRequest("PATCH", path, body, tokenValidation);
  }

  makeRequest(method, path, data, tokenValidation) {
    const body = JSON.stringify(data);
    const token = this.cookieService.getItem("token");
    const headers = {
      "Content-Type": "application/json"
    };

    tokenValidation && (headers.Authorization = `Bearer ${token}`);

    const handleError = async res => {
      const contentType = res.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const errorPayload = await res.json();
        return {
          status: res.status,
          ...errorPayload
        };
      } else {
        return {
          status: res.status,
          message: "Przepraszamy, coś poszło nie tak, spróbuj ponownie później."
        };
      }
    };

    const options = data ? { method, headers, body } : { method, headers };
    return fetch(path, options).then(res =>
      res.ok
        ? res.status === 204
          ? res
          : res.json()
        : handleError(res).then(error => Promise.reject(error))
    );
  }
}
