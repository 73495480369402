export const CMS_REQUEST_SUCCESS = "CMS_REQUEST_SUCCESS";
export const CMS_REQUEST_FAILURE = "CMS_REQUEST_FAILURE";
export const CMS_REQUEST = "CMS_REQUEST";

export const cmsRequestSuccess = (data) => ({
  type: CMS_REQUEST_SUCCESS,
  payload: data,
});

export const cmsRequestFailure = (data) => ({
  type: CMS_REQUEST_FAILURE,
  payload: data,
});

export const cmsRequest = () => ({
  type: CMS_REQUEST,
});
