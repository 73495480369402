import React from "react";
import styled, { keyframes } from "styled-components";

import { colors, mediaQueries } from "../styles/variable";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
`;

const spinner = keyframes`
from {transform: rotate(0deg);}
to {transform: rotate(360deg);}
`;

export const Loader = styled.div`
  position: absolute;
  top: calc(50% - 90px);
  border: 16px solid ${colors.concrete};
  border-radius: 50%;
  border-top: 16px solid ${colors.doveGray};
  width: 120px;
  height: 120px;
  animation: ${spinner} 2s linear infinite;

  ${mediaQueries.lg} {
    top: calc(50% - 120px);
  }
`;

export const LoaderComponent = () => (
  <Wrapper>
    <Loader />
  </Wrapper>
);
