export class AuthServiceFactory {
  constructor(apiUrl, httpService, cookieService) {
    this.apiUrl = apiUrl;
    this.httpService = httpService;
    this.cookieService = cookieService;
  }

  register = (values) =>
    this.httpService.post(`${this.apiUrl}/users/register/`, values, false);

  confirmRegistration = (uuid) =>
    this.httpService.get(`${this.apiUrl}/invitations/${uuid}/activate/`, false);

  login = (values) =>
    this.httpService.post(`${this.apiUrl}/token/`, values, false);

  userData = (id, token) =>
    this.httpService.get(`${this.apiUrl}/users/${id}/`, token, true);

  refreshToken = (token) =>
    this.httpService.post(`${this.apiUrl}/token/refresh/`, token, true);

  resetEmail = (email) =>
    this.httpService.post(
      `${this.apiUrl}/users/recover/reset-password-request/`,
      email,
      false
    );

  resetPassword = (uuid, values) =>
    this.httpService.post(
      `${this.apiUrl}/users/recover/${uuid}/reset-password/`,
      values,
      false
    );

  sendEmailList = (values) =>
    this.httpService.post(
      `${this.apiUrl}/invitations/email-list-invitation/`,
      values,
      true
    );
}
