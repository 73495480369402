import styled from "styled-components";

import { fontSize, colors, fontWeight, mediaQueries } from "../styles/variable";

export const SuccessInfoWrapper = styled.div`
  margin: 2rem 0 2rem;
`;

export const Title = styled.p`
  font-size: ${fontSize.extraLarge};
  font-weight: ${fontWeight.heavy};
  line-height: 3rem;
  margin: 3.5rem 0;
`;

export const AddInfo = styled.p`
  margin: 2.6rem 0;
  font-weight: ${fontWeight.semiBold};
  font-size: ${fontSize.xSmall};
  line-height: 1.6rem;

  ${mediaQueries.md} {
    width: 19.5rem;
  }
`;

export const LinkWrapper = styled.div`
  cursor: pointer;
  border-bottom: 1px solid ${colors.black};
  margin: 1rem auto;
  width: fit-content;
`;

export const BackArrowWrapper = styled.img`
  margin: 20rem 0 1rem;
  width: 18px;

  cursor: pointer;
`;
