import * as yup from "yup";

export const resetMailSchema = ({ t }) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t("validation.email"))
      .required(t("validation.required"))
  });

export const resetSchema = ({ t }) =>
  yup.object().shape({
    password: yup
      .string()
      .required(t("validation.required"))
      .min(8, t("validation.passwordLength")),
    repeatPassword: yup
      .string()
      .required(t("validation.required"))
      .oneOf([yup.ref("password"), ""], t("validation.repeatPassword"))
  });
