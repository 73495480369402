import React from "react";

import {
  RegistrationParagraphWrapper,
  RegistrationRegulationLink,
  RequiredField,
} from "./RegistrationFormComponents";
import { frontendUrl } from "../../apiBase";
import { routes } from "../../statics/routes";

export const RegulationWithLink = ({ content, required }) => (
  <div>
    <RegistrationParagraphWrapper>
      {required && <RequiredField>*</RequiredField>}
      {content[0]}
      <RegistrationRegulationLink
        href={`${frontendUrl}${routes.terms}`}
        target="_blank"
      >
        {content[1]}
      </RegistrationRegulationLink>
      {content[2]}
    </RegistrationParagraphWrapper>
  </div>
);
