import jwt_decode from "jwt-decode";

export const decodeToken = token => {
  try {
    const { user_id } = jwt_decode(token);
    return user_id;
  } catch (err) {
    return false;
  }
};
