import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { cookieService } from "./services";

import authEn from "./statics/locales/en/auth";
import authPl from "./statics/locales/pl/auth";
import contactEn from "./statics/locales/en/contact";
import contactPl from "./statics/locales/pl/contact";
import homeEn from "./statics/locales/en/home";
import homePl from "./statics/locales/pl/home";
import ordersEn from "./statics/locales/en/orders";
import ordersPl from "./statics/locales/pl/orders";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["cookie"],
    },
    resources: {
      en: {
        auth: authEn,
        contact: contactEn,
        home: homeEn,
        orders: ordersEn,
      },
      pl: {
        auth: authPl,
        contact: contactPl,
        home: homePl,
        orders: ordersPl,
      },
    },
    fallbackLng: "pl",
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on("languageChanged", (lang) => cookieService.saveItem("i18next", lang));
