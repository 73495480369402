import { cmsApiBaseUrl } from "../../apiBase";

export const CMS_EMPTY = "CMS_EMPTY";
export const CMS_NOT_RESPONDING = "CMS_NOT_RESPONDING";

export class CmsService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getContent = () =>
    Promise.all([
      this.httpService.get(`${cmsApiBaseUrl}/home-pages`, false),
      this.httpService.get(`${cmsApiBaseUrl}/footers`, false),
      this.httpService.get(`${cmsApiBaseUrl}/general-styles`, false),
      this.httpService.get(`${cmsApiBaseUrl}/live-stream-pages`, false),
      this.httpService.get(`${cmsApiBaseUrl}/streams`, false),
      this.httpService.get(`${cmsApiBaseUrl}/ticket-infos`, false),
      this.httpService.get(`${cmsApiBaseUrl}/terms-of-uses`, false),
      this.httpService.get(`${cmsApiBaseUrl}/faqs`, false),
    ]).then(
      ([
        homes,
        footers,
        styles,
        streamPageInfos,
        streams,
        ticketInfos,
        termsOfUses,
        faqs,
      ]) => {
        const home = homes[0],
          footer = footers,
          style = styles[0],
          streamPageInfo = streamPageInfos[0],
          termsOfUse = termsOfUses[0],
          stream = streams[0],
          faq = faqs[0];
        return home && streamPageInfo
          ? {
              home,
              footer,
              style,
              streamPageInfo,
              stream,
              ticketInfos,
              termsOfUse,
              faq,
            }
          : Promise.reject(CMS_EMPTY);
      }
    );
}
