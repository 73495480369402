const mediaQ = (size) => `@media only screen and (min-width: ${size}px)`;

export const colors = {
  black: "#000000",
  white: "#FFFFFF",
  brown: "#AA6E10",
  red: "#E90107",
  grey: "#00000029",
  yellow: "#FDB201",
  green: "#667A3B",
  spindle: "#B3ACF6",
  malibu: "#81D0F8",
  riptide: "#76EAC6",
  concrete: "#f3f3f3",
  doveGray: "#707070",
  silver: "#C7C7C7",
  tundora: "#454545",
  jacksonsPurple: "#202595",
  bilobaFlower: "#B0B2EE",
  gigas: "#4043A0",
  buddhaGold: "#CD9608",
  theme: "#F84445",
  alto: "#E0E0E0",
  codGray: "#151515",
  background: "#505050",
  shark: "#2D3034",
  cookieInfoBackground: "#5B5D60",
  transparent: "transparent",
  emperor: "#505050",
  red: "#F84445",
};

export const colorsRGBA = {
  emperor70: `rgba(80, 80, 80, 0.7)`,
  experor100: `rgba(80, 80, 80, 1)`,
  notActiveSourceButton: `rgba(255, 255, 255, 0.75)`,
  activeSourceButton: `rgba(248, 68, 69, 0.1)`,
};

export const mediaQueries = {
  xs: mediaQ(320),
  sm: mediaQ(576),
  md: mediaQ(850),
  lg: mediaQ(992),
  xl: mediaQ(1200),
  xxl: mediaQ(1440),
  xxxl: mediaQ(1600),
};

export const fontWeight = {
  light: 300,
  medium: 400,
  extraMedium: 500,
  semiBold: 600,
  heavy: 700,
  extraBold: 800,
  superBold: 900,
};

export const fontSize = {
  xxxSmall: "0.625rem",
  xxSmall: "0.7rem",
  xSmall: "0.8rem",
  small: "0.85rem",
  base: "16px",
  normal: "1rem",
  normalExtended: "1.1rem",
  medium: "1.2rem",
  mediumExtended: "1.3rem",
  large: "1.5rem",
  largeExtended: "1.8rem",
  extraLarge: "2rem",
  extraLargeExtended: "2.5rem",
  biggest: "3rem",
  biggestExtended: "4rem",
  offTheScale: "5rem",
};

export const fontFamily = {
  openSans: "'Open Sans', sans-serif",
  spartan: "'Spartan', sans-serif",
};

export const linearGradient = {
  mobileHomePageBackgroundVertical:
    "linear-gradient(180deg, #000000 0%, rgba(59, 59, 59, 0.697917) 35.94%, rgba(196, 196, 196, 0) 100%)",
  mobileHomePageBackgroundHorizontal:
    "linear-gradient(90deg, #000000 0%, rgba(53, 53, 53, 0.79) 63.23%, rgba(255, 255, 255, 0) 80%)",
  desktopHomePageBackgroundVertical:
    " linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.5) 56.25%, rgba(255, 255, 255, 0) 100%)",
  desktopHomePageBackgroundHorizontal:
    " linear-gradient(90deg, #000000 0%, rgba(59, 59, 59, 0.697917) 41.15%, rgba(196, 196, 196, 0) 100%)",
  playerBackground:
    "linear-gradient(180deg,rgba(255, 255, 255, 0.85),rgba(0, 0, 0, 1))",
  subPageBackground:
    "linear-gradient( 180deg,rgba(0, 0, 0, 0.75) 0%,rgba(0, 0, 0, 0.7125) 81.77%,rgba(0, 0, 0, 0) 100%)",
};
