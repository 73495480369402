import * as yup from "yup";

export const loginSchema = ({ t }) =>
  yup.object().shape({
    email: yup
      .string()
      .required(t("validation.required"))
      .email(t("validation.email")),
    password: yup.string().required(t("validation.required")),
  });

export const registerSchema = ({ t }) =>
  yup.object().shape({
    firstName: yup.string().required(t("validation.required")),
    lastName: yup.string().required(t("validation.required")),
    email: yup
      .string()
      .email(t("validation.email"))
      .required(t("validation.required")),
    password: yup
      .string()
      .required(t("validation.required"))
      .min(8, t("validation.passwordLength")),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref("password"), ""], t("validation.repeatPassword"))
      .min(3)
      .required(t("validation.required")),
    phoneNumber: yup
      .string()
      .nullable()
      .matches(new RegExp("[0-9]{9}"), t("validation.phoneNumber"))
      .max(9, t("validation.phoneNumberLength")),
    dataProcessing: yup.bool().oneOf([true], t("validation.required")),
    withdrawalOfConsent: yup.bool().oneOf([true], t("validation.required")),
  });
