import React from "react";
import { Formik, Field } from "formik";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";

import {
  loginRequest,
  getUserDataRequest,
} from "../../core/authentication/actions";
import { loginSchema } from "./schema";
import {
  Wrapper,
  TitleWrapper,
  FormWrapper,
  SubmitButton,
  LinkSectionWrapper,
  LinkWrapper,
  ErrorMessage,
  AccountActivationInfo,
  LinkToResetPassword,
  HaveAccountInfo,
} from "./Form";
import { Input } from "./Input";
import { PasswordResetWrapper } from "../resetPassword/PasswordResetWrapper";
import { themeColorSelector } from "../../core/cms/selectors";
import { decodeToken } from "../../helper/decodeToken";
import { routes } from "../../statics/routes";
import { mediaQueries } from "../../styles/variable";

const LoginLinkSectionWrapper = styled(LinkSectionWrapper)`
  margin: 0.3rem 0;

  ${mediaQueries.lg} {
    margin: 2.8rem 0 1rem;
  }
`;

const initialValues = {
  email: "",
  password: "",
};

const input = [
  { type: "email", value: "email", name: "email" },
  { type: "password", value: "password", name: "password" },
];

const LoginFormContainer = ({
  t,
  handleActivity,
  isLoginActive,
  isAccountActivated,
  isResetFormActive,
  uuid,
  setIsOpen,
  loginRequest,
  errorMessage,
  errorDetail,
  cms,
  refresh,
  access,
  getUserDataRequest,
}) => {
  const [isResetModalOpen, setIsResetModalOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  const history = useHistory();

  const submitLogin = ({ email, ...values }) => {
    const loginData = {
      ...values,
      email: email.toLowerCase(),
    };
    loginRequest(loginData);
  };

  React.useEffect(() => {
    if (refresh) {
      setIsOpen(false);
      history.push(routes.stream);
    }
    errorDetail && setError(t("errors.incorrect credentials"));
    const decoded = decodeToken(access);
    decoded && getUserDataRequest(decoded, access);
  }, [errorDetail, refresh]);

  const renderLoginForm = () => {
    if (isResetModalOpen || isResetFormActive) {
      return (
        <PasswordResetWrapper
          isResetFormActive={isResetFormActive}
          setIsResetModalOpen={setIsResetModalOpen}
          uuid={uuid}
        />
      );
    }
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema({ t })}
        onSubmit={(values) => submitLogin(values)}
      >
        {({ handleSubmit }) => (
          <Wrapper>
            <FormWrapper onSubmit={handleSubmit}>
              <TitleWrapper>{t("login title")}</TitleWrapper>

              {isAccountActivated && (
                <>
                  <AccountActivationInfo>
                    {t("register success 2")}
                  </AccountActivationInfo>
                </>
              )}
              {input.map((item) => (
                <Field
                  key={item.value}
                  name={item.value}
                  type={item.type}
                  placeholder={t(`user.${item.name}`)}
                  component={Input}
                />
              ))}
              <SubmitButton type="submit" themeColor={themeColorSelector(cms)}>
                {t("login button")}
              </SubmitButton>
              <ErrorMessage>{error}</ErrorMessage>
              <ErrorMessage>{errorMessage}</ErrorMessage>

              <LinkToResetPassword onClick={() => setIsResetModalOpen(true)}>
                {t("reset.forgot password")}
              </LinkToResetPassword>

              <LoginLinkSectionWrapper>
                <HaveAccountInfo>{t("no account")}</HaveAccountInfo>
                <LinkWrapper onClick={() => handleActivity(!isLoginActive)}>
                  {t("register button")}
                </LinkWrapper>
              </LoginLinkSectionWrapper>
            </FormWrapper>
          </Wrapper>
        )}
      </Formik>
    );
  };

  return renderLoginForm();
};

const mapStateToProps = ({
  user: { errorMessage, errorDetail, access, refresh },
  cms,
}) => ({
  errorMessage: errorMessage,
  errorDetail: errorDetail,
  access: access,
  refresh: refresh,
  cms,
});

const mapDispatchToProps = {
  loginRequest,
  getUserDataRequest,
};

export const LoginForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("auth")(LoginFormContainer));
