import { combineEpics } from "redux-observable";

import { authEpicFactory } from "./authentication/epic";
import { cmsEpicFactory } from "./cms/epic";

import { authService, cookieService, cmsService } from "../services";

export const rootEpic = combineEpics(
  authEpicFactory(authService, cookieService),
  cmsEpicFactory(cmsService)
);
