import styled from "styled-components";

import {
  mediaQueries,
  colors,
  fontSize,
  fontWeight,
} from "../../styles/variable";

export const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 26.875rem;
  background-color: ${colors.black};

  ${mediaQueries.md} {
    min-height: 11rem;
  }

  ${mediaQueries.lg} {
    min-height: 14.875rem;
  }
`;

export const SponsorIconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  min-height: 9rem;
  width: 90%;
  border-bottom: 0.25px solid ${colors.white};

  ${mediaQueries.sm} {
    justify-content: space-between;
    align-items: center;
    width: 90%;
    min-height: 5.375rem;
    margin: 0 auto;
  }

  ${mediaQueries.lg} {
    justify-content: space-between;
    min-height: 10.375rem;
  }
`;

export const SponsorIcon = styled.img`
  margin: 1rem 0.5rem;
  width: 6.25rem;
  height: 1.75rem;

  ${mediaQueries.lg} {
    width: 10.75rem;
    height: 2.25rem;

    :first-child {
      padding-left: 2rem;
    }

    :last-child {
      padding-right: 2rem;
    }
  }
`;

export const LinksSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 1rem auto;
  min-height: 6.875rem;
  width: 90%;

  ${mediaQueries.md} {
    min-height: 4.5rem;
    flex-direction: row;
    align-items: center;
  }
`;

export const Link = styled.a`
  display: flex;
  margin-top: 1.5rem;
  color: ${colors.white};
  font-size: ${fontSize.xxSmall};
  font-weight: ${fontWeight.semiBold};
  cursor: pointer;
  text-decoration: none;

  ${mediaQueries.md} {
    margin-top: 0;
    margin-right: 4rem;
  }
`;

export const Rights = styled.div`
  display: flex;
  padding-top: 6.125rem;
  padding-bottom: 2rem;
  text-align: center;
  color: ${colors.white};
  font-size: ${fontSize.xxSmall};
  font-weight: ${fontWeight.semiBold};
  margin: 0 auto;

  ${mediaQueries.md} {
    margin: 0;
    padding: 0;
    padding-left: 3rem;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueries.md} {
    flex-direction: row;
  }
`;
