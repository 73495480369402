import React from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import {
  Wrapper,
  SponsorIconsWrapper,
  SponsorIcon,
  LinksSectionWrapper,
  Link,
  Rights,
  LinksWrapper,
} from "./Components";
import { sponsorsSelector } from "../../core/cms/selectors";
import { s3Url, cmsApiBaseUrl } from "../../apiBase";
import { routes } from "../../statics/routes";

const FooterContainer = ({ t, cms }) => {
  const history = useHistory();

  return (
    <Wrapper>
      <SponsorIconsWrapper>
        {sponsorsSelector(cms)?.map((item) => (
          <a href={item.link} target="_blank" rel="noopener" key={item.link}>
            <SponsorIcon
              alt="partner logo"
              src={`${cmsApiBaseUrl}${item.Icon?.url}`}
            />
          </a>
        ))}
      </SponsorIconsWrapper>
      <LinksSectionWrapper>
        <LinksWrapper>
          <Link onClick={() => history.push(routes.terms)}>
            {t("footer.regulations")}
          </Link>
          <Link onClick={() => history.push(routes.faq)}>
            {t("footer.faq")}
          </Link>
          <Link onClick={() => history.push(routes.contact)}>
            {t("footer.contact")}
          </Link>
        </LinksWrapper>
        <Rights>{t("footer.rights")}</Rights>
      </LinksSectionWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  cms: state.cms,
});

export const Footer = connect(mapStateToProps)(
  withTranslation("home")(FooterContainer)
);
