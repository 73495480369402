export const apiUrl = "https://api-wotore.hal.skygate.io/api";
export const s3Url = process.env.REACT_APP_S3_URL;
export const hotpayKey = process.env.REACT_APP_HOTPAY_KEY;
export const paySafeCardKey = process.env.REACT_APP_PAYSAFECARD_KEY;
export const frontendUrl = "https:///mma.hal.skygate.io";
export const facebookPageId = process.env.REACT_APP_FACEBOOK_PAGE_ID;
export const iconsUrl = "/icons";
export const imagesUrl = "/images";
export const cmsApiBaseUrl = "https://cms-wotore.hal.skygate.io";

export const hotpayURL = "https://platnosc.hotpay.pl/";
export const paySafeCardURL = "https://psc.hotpay.pl/";
export const playerUrl = "api-beeonair.app.insysgo.pl";
export const assetCodenameEUW = "beeonair-live-euw";
export const assetCodenameEUC = "beeonair-live-euc";
