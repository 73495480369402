import React from "react";
import { createPortal } from "react-dom";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";

import { clearState } from "../../core/authentication/actions";
import { RegisterForm } from "./RegisterForm";
import { LoginForm } from "./LoginForm";
import { InfoForm } from "./InfoForm";
import { useOutsideCloser } from "../../hooks/useOutsideCloser";
import { colors } from "../../styles/variable";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${colors.alto};
  opacity: 0.75;
`;

const ModalContainer = ({
  isOpen,
  setIsOpen,
  onClose,
  isAccountActivated,
  isResetFormActive,
  hasInvitationCode,
  uuid,
  isInfoModalActive,
  setIsInfoModalActive,
  clearState,
}) => {
  const [isLoginActive, setIsLoginActive] = React.useState(true);
  const container = document.getElementById("modal");

  React.useEffect(() => {
    const isLoginActive = isAccountActivated || isResetFormActive;
    isLoginActive && setIsLoginActive(true);
    hasInvitationCode && setIsLoginActive(false);
  }, [isAccountActivated, isResetFormActive]);

  const ref = React.useRef();
  useOutsideCloser(ref, () => {
    setIsInfoModalActive && setIsInfoModalActive(true);
    clearState();
    onClose(!isOpen);
  });

  const renderForm = () => {
    if (isInfoModalActive) {
      return (
        <InfoForm
          isLoginActive={isLoginActive}
          setIsLoginActive={setIsLoginActive}
          setIsInfoModalActive={setIsInfoModalActive}
        />
      );
    }
    if (isLoginActive) {
      return (
        <LoginForm
          handleActivity={setIsLoginActive}
          isLoginActive={isLoginActive}
          isAccountActivated={isAccountActivated}
          isResetFormActive={isResetFormActive}
          uuid={uuid}
          setIsOpen={setIsOpen}
        />
      );
    }

    return (
      <RegisterForm
        handleActivity={setIsLoginActive}
        isLoginActive={isLoginActive}
        setIsOpen={setIsOpen}
      />
    );
  };

  return (
    isOpen &&
    createPortal(
      <ModalWrapper className="modal">
        <BackgroundWrapper />
        <div ref={ref}>{renderForm()}</div>
      </ModalWrapper>,
      container
    )
  );
};

const mapDispatchToProps = {
  clearState,
};

export const Modal = connect(
  null,
  mapDispatchToProps
)(withTranslation("auth")(ModalContainer));
