import React from "react";
import { withTranslation } from "react-i18next";

import { SendEmailForm } from "./SendEmailForm";
import { ResetPasswordForm } from "./ResetPasswordForm";

const PasswordResetWrapperContainer = ({
  t,
  isResetFormActive,
  setIsResetModalOpen,
  uuid,
}) =>
  isResetFormActive ? (
    <ResetPasswordForm uuid={uuid} />
  ) : (
    <SendEmailForm setIsResetModalOpen={setIsResetModalOpen} />
  );

export const PasswordResetWrapper = withTranslation("auth")(
  PasswordResetWrapperContainer
);
