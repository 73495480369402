import React from "react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

import {
  colors,
  fontWeight,
  fontSize,
  fontFamily,
  mediaQueries,
} from "../../styles/variable";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${colors.black};
`;

const SingleDivWrapper = styled.div`
  display: ${(props) => (props.isActive ? "none" : "flex")};
  padding: 0 0.1rem 0.4rem;
  font-family: ${fontFamily.spartan};
  font-size: ${fontSize.normal};
  line-height: 1.25rem;
  cursor: pointer;
  color: ${colors.white};

  ${mediaQueries.xl} {
    display: flex;
    padding: 0 0.1rem;
    line-height: 2.8rem;
    font-weight: ${(props) =>
      props.isActive ? fontWeight.heavy : fontWeight.light};
  }
`;

const SlashWrapper = styled.div`
  display: none;

  ${mediaQueries.xl} {
    display: flex;
    color: ${colors.white};
    margin: 0 0.1rem;
    font-weight: ${fontWeight.light};
  }
`;

export const languages = [
  { code: "pl", label: "PL" },
  { code: "en", label: "EN" },
];

const LanguageInfoContainer = ({ i18n }) => (
  <Wrapper>
    {languages.map((language, i) => (
      <SingleDivWrapper
        key={language.code}
        onClick={() => i18n.changeLanguage(language.code)}
        isActive={i18n.language === language.code}
      >
        {language.label}
        {i === 0 && <SlashWrapper>{"/"}</SlashWrapper>}
      </SingleDivWrapper>
    ))}
  </Wrapper>
);

export const LanguageInfo = withTranslation("home")(LanguageInfoContainer);
