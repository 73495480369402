import React from "react";
import { withTranslation } from "react-i18next";

import * as S from "./Form";
import {
  RegistrationParagraphWrapper,
  SingleRegulationWrapper,
  RequiredField,
  RegulationCheckboxWrapper,
} from "./RegistrationFormComponents";
import { RegulationWithLink } from "./RegulationWithLink";

const RegulationContainer = ({
  t,
  errors,
  touched,
  data: { content, required, fieldName },
}) => (
  <SingleRegulationWrapper>
    <S.CheckboxSectiom>
      <RegulationCheckboxWrapper
        type="checkbox"
        name={fieldName}
        required={required}
      />
      <div>
        {content.length === 1 ? (
          content.map((item) => (
            <RegistrationParagraphWrapper key={item}>
              {required && <RequiredField>*</RequiredField>}
              {item}
            </RegistrationParagraphWrapper>
          ))
        ) : (
          <RegulationWithLink content={content} required={required} />
        )}
      </div>
    </S.CheckboxSectiom>
    {errors[fieldName] && touched[fieldName] && (
      <S.ErrorMessage>{errors[fieldName]}</S.ErrorMessage>
    )}
  </SingleRegulationWrapper>
);

export const Regulation = withTranslation("auth")(RegulationContainer);
