import * as actions from "./actions";

export const cmsReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.CMS_REQUEST_SUCCESS:
      return { ...state, data: action.payload };
    case actions.CMS_REQUEST_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
