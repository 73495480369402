import React from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Formik, Field } from "formik";
import { connect } from "react-redux";

import { resetPasswordRequest } from "../../core/authentication/actions";
import {
  Wrapper,
  TitleWrapper,
  ErrorMessage,
  FormWrapper,
  SubmitButton,
  SingleLinkSectionWrapper,
  LinkWrapper,
  InfoWrapper,
} from "../auth/Form";
import {
  Title,
  SuccessInfoWrapper,
} from "../../components/InformationComponents";
import { Input } from "../auth/Input";
import { themeColorSelector } from "../../core/cms/selectors";
import { routes } from "../../statics/routes";
import { resetSchema } from "./schema";

const initialValues = {
  password: "",
  repeatPassword: "",
};

const input = [
  { type: "password", value: "password", name: "password" },
  {
    type: "password",
    value: "repeatPassword",
    name: "repeat password",
  },
];

const ResetPasswordFormContainer = ({
  t,
  uuid,
  i18n,
  resetPasswordRequest,
  isResetPasswordCompleted,
  passwordError,
  message,
  cms,
}) => {
  const [error, setError] = React.useState("");
  const history = useHistory();

  const submitEmail = ({ password }) => {
    const data = { password };
    resetPasswordRequest(uuid, data);
  };

  React.useEffect(() => {
    passwordError && setError(t("errors.common password"));
  }, [passwordError]);

  return isResetPasswordCompleted ? (
    <Wrapper>
      <InfoWrapper>
        <SuccessInfoWrapper>
          <Title>{t("reset.success")}</Title>
        </SuccessInfoWrapper>
        <SingleLinkSectionWrapper>
          <LinkWrapper
            onClick={() =>
              history.push({
                pathname: routes.main,
                state: { isResetFinished: true },
              })
            }
          >
            {t("login button")}
          </LinkWrapper>
        </SingleLinkSectionWrapper>
      </InfoWrapper>
    </Wrapper>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={resetSchema({ t })}
      onSubmit={(values) => submitEmail(values)}
    >
      {({ handleSubmit }) => (
        <Wrapper>
          <FormWrapper onSubmit={handleSubmit}>
            <TitleWrapper>{t("reset.title")}</TitleWrapper>
            {input.map((item) => (
              <Field
                key={item.value}
                name={item.value}
                type={item.type}
                placeholder={t(`user.${item.name}`)}
                component={Input}
              />
            ))}
            <SubmitButton type="submit" themeColor={themeColorSelector(cms)}>
              {t("reset.submit password")}
            </SubmitButton>
            <ErrorMessage>{error}</ErrorMessage>
            <ErrorMessage>{message}</ErrorMessage>
          </FormWrapper>
        </Wrapper>
      )}
    </Formik>
  );
};

const mapStateToProps = ({
  user: { isResetPasswordCompleted, resetPasswordError, resetPasswordMessage },
  cms,
}) => ({
  isResetPasswordCompleted,
  passwordError: resetPasswordError,
  message: resetPasswordMessage,
  cms,
});

const mapDispatchToProps = {
  resetPasswordRequest,
};

export const ResetPasswordForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("auth")(ResetPasswordFormContainer));
