import styled from "styled-components";

import {
  Wrapper,
  InputField,
  InputSection,
  SubmitButton,
  ParagraphWrapper,
  RegulationLink,
  TitleWrapper,
  CheckboxWrapper,
} from "./Form";
import {
  mediaQueries,
  fontSize,
  colors,
  fontWeight,
} from "../../styles/variable";

export const RegistrationWrapper = styled(Wrapper)`
  width: 19rem;
  left: calc(50% - 9.5rem);

  ${mediaQueries.md} {
    height: 42rem;
    max-height: 94vh;
    top: calc(50% - 45vh);
    width: 52rem;
    left: calc(50% - 26rem);
  }
`;

export const RegistrationInputSection = styled(InputSection)`
  ${mediaQueries.md} {
    width: 48.5%;
  }
`;

export const RegistrationInputField = styled(InputField)`
  padding-left: 1.2rem;
  height: 2.5rem;
  display: flex;
  margin: 0.75rem 0;

  ${mediaQueries.md} {
    margin-bottom: ${({ message }) => (message ? "0" : "1.25rem")};
    height: 3rem;
    padding: 0 0 0 1.5rem;
  }
`;

export const InputsArea = styled.div`
  width: 100%;
  ${mediaQueries.md} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const RegistrationFormWrapper = styled.form`
  margin: 0 auto;
  width: 89%;
`;

export const RegistrationSubmitButton = styled(SubmitButton)`
  width: 100%;
  margin-top: 2.3rem;

  ${mediaQueries.md} {
    margin: 1.4rem auto 0;
    width: 17rem;
    display: block;
  }
`;

export const RegistrationParagraphWrapper = styled(ParagraphWrapper)`
  font-size: ${fontSize.xxxSmall};
  line-height: 1rem;
  height: fit-content;
  display: block;
  font-weight: ${fontWeight.light};
`;

export const RegistrationRegulationLink = styled(RegulationLink)`
  font-size: ${fontSize.xxxSmall};
  font-weight: ${fontWeight.light};
`;

export const SingleRegulationWrapper = styled.div`
  margin: 1.5rem 0 0;

  ${mediaQueries.md} {
    margin: 0.5rem 0 0;
  }
`;

export const RegistrationTitleWrapper = styled(TitleWrapper)`
  ${mediaQueries.md} {
    margin: 2rem 0 0.5rem;
  }
`;

export const RequiredField = styled.span`
  color: ${colors.red};
  font-size: ${fontSize.xSmall};
  font-weight: ${fontWeight.superBold};
  margin: 0;
`;

export const RegulationCheckboxWrapper = styled(CheckboxWrapper)`
  margin-right: 0.3rem;
`;
