import React from "react";
import styled, { createGlobalStyle, css } from "styled-components";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { MobileHeader } from "../components/header/MobileHeader";
import { Modal } from "../components/auth/Modal";
import { Footer } from "../components/footer/Footer";
import { CookieInfo } from "./CookieInfo";
import { Header } from "./header/Header";
import { FacebookMessenger } from "./FacebookMessenger";
import { cookieService } from "../services";
import { homePageBackground } from "../core/cms/selectors";
import { cmsApiBaseUrl } from "../apiBase";
import { routes } from "../statics/routes";
import { fontFamily, linearGradient, mediaQueries } from "../styles/variable";

const GlobalStyle = createGlobalStyle`
    body {
    font-family:${fontFamily.spartan};

          overflow-x:hidden;
          width: 100vw;
          height: 100vh;
          margin: 0;
          padding: 0;
    }
`;

const checkBackgroundStatus = (backgroundImage) =>
  backgroundImage && `${cmsApiBaseUrl}${backgroundImage}`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  min-height: 100vh;
  ${({ isActive, backgroundImage }) =>
    isActive
      ? css`
          background-image: url(${checkBackgroundStatus(backgroundImage)});
          background-image: ${linearGradient.mobileHomePageBackgroundVertical},
            ${linearGradient.mobileHomePageBackgroundHorizontal},
            url(${checkBackgroundStatus(backgroundImage)});
          background-repeat: no-repeat;
          background-size: 60rem 100%, 60rem 100%, cover;
          background-position: 80% 0, 80% 0, 80% -25rem;

          ${mediaQueries.md} {
            background-size: cover;
            background-position: 70% -10rem;
            background-image: url(${checkBackgroundStatus(backgroundImage)});
            background-image: ${linearGradient.desktopHomePageBackgroundVertical},
              ${linearGradient.desktopHomePageBackgroundHorizontal},
              url(${checkBackgroundStatus(backgroundImage)});
          }

          ${mediaQueries.lg} {
            background-position-y: -10rem;
          }

          ${mediaQueries.xxxl} {
            background-position-y: -12rem;
          }
        `
      : css`
          background-color: white;
        `}
`;

const LayoutContainer = ({ t, children, backgroundImage }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isCookieInfoOpen, setIsCookieInfoOpen] = React.useState(true);
  const history = useHistory();
  const isCookiesAccepted = cookieService.getItem("acceptedCookies");

  const isCorrectPath = history.location.pathname === routes.main;
  const isAccountActivated =
    history.location.state && history.location.state.isConfirming;
  const isResetFormActive =
    history.location.state && history.location.state.isResetting;
  const uuid = history.location.state && history.location.state.id;
  const isResetFinished =
    history.location.state && history.location.state.isResetFinished;
  const hasInvitationCode =
    history.location.state && history.location.state.hasInvitationCode;
  const isAuthModalOpen =
    isOpen ||
    isAccountActivated ||
    isResetFormActive ||
    isResetFinished ||
    hasInvitationCode;

  const handleClose = () => {
    setIsOpen(!isOpen);
    history.location.state = false;
  };

  const scroll = () =>
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });

  return (
    <PageWrapper isActive={isCorrectPath} backgroundImage={backgroundImage}>
      <GlobalStyle />
      <MobileHeader
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        isCorrectPath={isCorrectPath}
        scroll={scroll}
      />
      <Header
        setIsOpen={setIsOpen}
        isCorrectPath={isCorrectPath}
        scroll={scroll}
      />
      {isAuthModalOpen && (
        <Modal
          isOpen={isAuthModalOpen}
          setIsOpen={setIsOpen}
          onClose={handleClose}
          isAccountActivated={isAccountActivated}
          isResetFormActive={isResetFormActive}
          hasInvitationCode={hasInvitationCode}
          uuid={uuid}
          isInfoModalActive={false}
        />
      )}

      <div>{children}</div>
      {isCookieInfoOpen && !isCookiesAccepted && (
        <CookieInfo
          isCookieInfoOpen={isCookieInfoOpen}
          setIsCookieInfoOpen={setIsCookieInfoOpen}
        />
      )}
      <Footer />
      <FacebookMessenger />
    </PageWrapper>
  );
};

const mapStateToProps = ({ cms }) => ({
  backgroundImage: homePageBackground(cms),
});

export const Layout = connect(mapStateToProps)(LayoutContainer);
