export const arrowIcon = `/icons/arrow.svg`;
export const blikIcon = `/icons/blik.svg`;
export const bluemediaIcon = `/icons/bluemedia.svg`;
export const cardsIcon = `/icons/cards.svg`;
export const exitIcon = `/icons/exit.svg`;
export const leftArrow = `/icons/leftArrow.svg`;
export const hotpayIcon = `/icons/hotpay.svg`;
export const logoIcon = `/icons/logo.svg`;
export const mastercardIcon = `/icons/mastercard.svg`;
export const partnerIcon1 = `/icons/partner2.svg`;
export const partnerIcon2 = `/icons/partner4.svg`;
export const partnerIcon3 = `/icons/partner3.svg`;
export const partnerIcon4 = `/icons/partner1.svg`;
export const paypalIcon = `/icons/paypal.svg`;
export const transferIcon = `/icons/bankTransfer.svg`;
export const transferEnIcon = `/icons/bankTransferEn.svg`;
export const visaIcon = `/icons/visa.svg`;
export const whiteArrow = `/icons/whiteArrow.svg`;
export const paySafeCardIcon = `/icons/paySafeCardLogo.svg`;
export const paySafeCardInfo = `/icons/paySafeCardInfo.svg`;
export const paySafeCardInfoEn = `/icons/paySafeCardInfoEn.svg`;
