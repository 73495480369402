import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

import { cookieService } from "../services";
import { exitIcon } from "../statics/configs/icons";
import { colors, fontSize, fontWeight, mediaQueries } from "../styles/variable";

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;

  background-color: ${colors.cookieInfoBackground};
  width: 100vw;
  height: 12rem;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${mediaQueries.sm} {
    bottom: 1.5rem;
    right: 6.25rem;
    width: 22rem;
    height: 9rem;
  }
`;

const ExitIcon = styled.img`
  margin-right: 1.3rem;
  margin-top: 1.3rem;
  cursor: pointer;

  ${mediaQueries.sm} {
    margin-right: 1rem;
    margin-top: 0.9rem;
  }
`;

const Info = styled.div`
  width: calc(90% - 6rem);
  height: 5rem;
  color: ${colors.white};
  font-weight: ${fontWeight.extraMedium};
  font-size: ${fontSize.xSmall};
  line-height: 1.7rem;
  margin-right: 6rem;
  margin-top: 0.75rem;

  ${mediaQueries.sm} {
    width: 18rem;
    line-height: 1.4rem;
    margin-right: 2.5rem;
    margin-top: 0.5rem;
  }
`;

const CookieInfoContainer = ({ t, setIsCookieInfoOpen, isCookieInfoOpen }) => {
  const handleCloseCookieBar = () => {
    setIsCookieInfoOpen(!isCookieInfoOpen);
    cookieService.saveItem("acceptedCookies", true);
  };

  return (
    <Wrapper>
      <ExitIcon alt="exit" src={exitIcon} onClick={handleCloseCookieBar} />
      <Info>{t("cookies")}</Info>
    </Wrapper>
  );
};

export const CookieInfo = withTranslation("home")(CookieInfoContainer);
