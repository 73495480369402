const dataHomePageSelector = (state) => state.data?.home;
const globalStylesSelector = (state) => state?.data?.style;
const footerSelector = (state) => state.data?.footer;
const streamPageInfoSelector = (state) => state.data?.streamPageInfo;
const ticketsInfoSelector = (state) => state.data?.ticketInfos;
const streamsSelector = (state) => state.data?.stream;

export const titleSelector = (state, lan) =>
  dataHomePageSelector(state)?.[`title_${lan}`];

export const headlineSelector = (state, lan) =>
  dataHomePageSelector(state)?.[`headline_${lan}`];

export const logoUrlSelector = (state) =>
  dataHomePageSelector(state)?.wotore_logo?.url;

export const errorSelector = (state) => state.error;

export const ticketInfoSelector = (state, lan) =>
  dataHomePageSelector(state)?.[`ticket_info_${lan}`];

export const editionNumberSelector = (state, lan) =>
  dataHomePageSelector(state)?.[`edition_number_${lan}`];

export const sponsorsSelector = (state) => footerSelector(state);

export const homePageBackground = (state) =>
  globalStylesSelector(state)?.background_image?.url;

export const themeColorSelector = (state) =>
  globalStylesSelector(state)?.theme_color;

export const liveStreamEventTitleSelector = (state, lan) =>
  streamPageInfoSelector(state)?.[`title_${lan}`];

export const liveStreamEventEditionSelector = (state) =>
  streamPageInfoSelector(state)?.edition_number;

export const liveEventSubtitleSelector = (state, lan) =>
  streamPageInfoSelector(state)?.[`subtitle_${lan}`];

export const paymentVerificationInfoSelector = (state, lan, num) =>
  streamPageInfoSelector(state)?.[`payment_verification_info${num}_${lan}`];

export const paymentFailureInfoSelector = (state, lan, num) =>
  streamPageInfoSelector(state)?.[`payment_failure_info${num}_${lan}`];

export const paymentSucceccInfoSelector = (state, lan, num) =>
  streamPageInfoSelector(state)?.[`payment_success_info${num}_${lan}`];

export const ticketsDetailsSelector = (state) => ticketsInfoSelector(state);

export const termsOfUseSelecetor = (state, lan) =>
  state.data?.termsOfUse?.[`content_${lan}`];

export const faqsSelector = (state, lan) => state.data?.faq?.[`content_${lan}`];

export const liveStreamActivitySelector = (state) =>
  streamsSelector(state)?.live_stream_section_active;

export const retransmissionStreamActivitySelector = (state) =>
  streamsSelector(state)?.retransmission_active;

export const retransmissionStreamLinkSelector = (state) =>
  streamsSelector(state)?.link_to_retransmision;
