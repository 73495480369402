import { apiUrl } from "../apiBase";
import { AuthServiceFactory } from "./auth/index";
import { EventOrdersFactory } from "./eventOrders/index";
import { HttpServices } from "./HttpService";
import { CookieService } from "./CookieService";
import { StreamServiceFactory } from "./stream";
import { CmsService } from "./cms";

export const cookieService = new CookieService();
export const httpService = new HttpServices(cookieService);
export const authService = new AuthServiceFactory(
  apiUrl,
  httpService,
  cookieService
);
export const eventOrdersService = new EventOrdersFactory(apiUrl, httpService);
export const streamService = new StreamServiceFactory(apiUrl, httpService);
export const cmsService = new CmsService(httpService);
