import cookie from "js-cookie";

export class CookieService {
  saveItem = (name, value) => {
    cookie.set(name, value);
  };
  getItem = (name) => {
    return cookie.get(name);
  };
  removeItem = (name) => {
    cookie.remove(name);
  };
}
