import { combineEpics, ofType } from "redux-observable";
import { pluck, switchMap, tap, catchError, map } from "rxjs/operators";
import { of, from } from "rxjs";

import * as actions from "./actions";

export const authEpicFactory = (authService, cookieService) => {
  const loginEpic = (action$) =>
    action$.pipe(
      ofType(actions.LOGIN_REQUEST),
      pluck("payload"),
      switchMap((credentials) =>
        from(authService.login(credentials)).pipe(
          map((response) => actions.loginSuccess(response)),
          tap((item) => {
            const { access, refresh } = item.payload;
            cookieService.saveItem("token", access);
            cookieService.saveItem("refresh", refresh);
          }),
          catchError((error) => of(actions.loginFailure(error)))
        )
      )
    );

  const registerEpic = (action$) =>
    action$.pipe(
      ofType(actions.REGISTER_REQUEST),
      pluck("payload"),
      switchMap((registerData) =>
        from(authService.register(registerData)).pipe(
          map((response) => actions.registerSuccess(response)),
          catchError((error) => of(actions.registerFailure(error)))
        )
      )
    );

  const sendResetEmail = (action$) =>
    action$.pipe(
      ofType(actions.SEND_RESET_EMAIL_REQUEST),
      pluck("payload"),
      switchMap((data) =>
        from(authService.resetEmail(data)).pipe(
          map((response) => actions.sendResetEmailSuccess(response)),
          catchError((error) => of(actions.sendResetEmailFailure(error)))
        )
      )
    );

  const resetPassword = (action$) =>
    action$.pipe(
      ofType(actions.RESET_PASSWORD_REQUEST),
      pluck("payload"),
      switchMap((data) => {
        const { uuid, newPassword } = data;
        return from(authService.resetPassword(uuid, newPassword)).pipe(
          map((response) => actions.resetPasswordSuccess(response)),
          catchError((error) => of(actions.resetPasswordFailure(error)))
        );
      })
    );

  const getUserData = (action$) =>
    action$.pipe(
      ofType(actions.GET_USER_DATA_REQUEST),
      pluck("payload"),
      switchMap(({ id, token }) =>
        from(authService.userData(id, token)).pipe(
          map((response) => actions.getUserDataSuccess(response)),
          catchError((error) => of(actions.getUserDataFailure(error)))
        )
      )
    );

  const refreshToken = (action$) =>
    action$.pipe(
      ofType(actions.REFRESH_TOKEN_REQUEST),
      pluck("payload"),
      switchMap(({ refresh }) =>
        from(authService.refreshToken({ refresh })).pipe(
          map((response) => {
            cookieService.saveItem("token", response.access);
            return actions.refreshTokenSuccess(response);
          }),
          catchError((error) => {
            cookieService.removeItem("token");
            cookieService.removeItem("refresh");
            return of(actions.refreshTokenFailure(error));
          })
        )
      )
    );

  return combineEpics(
    loginEpic,
    registerEpic,
    sendResetEmail,
    resetPassword,
    refreshToken,
    getUserData
  );
};
