import styled, { css } from "styled-components";

import {
  colors,
  fontSize,
  fontWeight,
  mediaQueries,
} from "../../styles/variable";

export const HeaderWrapper = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  ${({ isActive }) =>
    isActive
      ? css`
          background-color: transparent;
        `
      : css`
          background-color: ${colors.black};
          position: sticky;
          top: 0;
        `}

  ${mediaQueries.xl} {
    display: flex;
  }
`;

export const LogoWrapper = styled.img`
  height: 70px;
  width: 61px;
  margin: 0 2.5rem;

  ${mediaQueries.xl} {
    margin: 0 6.5rem;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
`;

export const RegisterButton = styled.div`
  font-size: ${fontSize.normal};
  font-weight: ${fontWeight.semiBold};
  text-decoration: uppercase;
  line-height: 2.8rem;
  border: none;
  width: 100%;
  color: ${({ themeColor }) => themeColor};
  background-color: transparent;
  padding: 0 1.5rem;
  cursor: pointer;
  width: 8.6rem;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  border: 2px solid ${({ themeColor }) => themeColor};
  cursor: pointer;
  margin: 0 6rem 0 2rem;
`;

export const LogoutWrapper = styled.div`
  width: 8.6rem;
  margin: 0 3rem 0 2rem;
  color: ${({ themeColor }) => themeColor};
  border: 2px solid ${({ themeColor }) => themeColor};
  line-height: 2.8rem;
  text-align: center;
  padding: 0 1.5rem;
  font-weight: ${fontWeight.semiBold};
  font-size: ${fontSize.normal};
  cursor: pointer;

  ${mediaQueries.xl} {
    margin: 0 6rem 0 2rem;
  }
`;

export const LinkWrapper = styled.div`
  cursor: pointer;
  color: ${colors.white};
  font-size: ${fontSize.normal};
  font-weight: ${fontWeight.semiBold};
  line-height: 2.8rem;
  letter-spacing: -0.01em;
  margin-left: 1rem;
  width: 8rem;
  text-align: center;

  ${mediaQueries.xl} {
    margin-left: 2rem;
  }
`;

export const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0.3rem;
  height: 5.6rem;

  ${mediaQueries.xl} {
    display: none;
  }

  background-color: ${(props) =>
    props.isActive
      ? `${colors.black}`
      : props.isCorrectPath
      ? "transparent"
      : `${colors.black}`};

  ${({ isCorrectPath }) =>
    !isCorrectPath &&
    css`
      position: sticky;
      top: 0;
    `};
`;

export const MobileLogoWrapper = styled.img`
  width: 57px;
  height: 49px;
  margin-left: 1.6rem;
  margin-top: 1.25rem;
`;

export const Hamburger = styled.div`
  margin: 2rem 1rem;
  top: 0;
  width: 30px;
  background-color: transparent;
  position: relative;

  :before,
  :after,
  div {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: ${colors.white};

    transition: ${(props) =>
      props.isActive
        ? "top 0.2s ease-in-out, transform 0.2s ease-in-out 0.2s"
        : "transform 0.2s, top 0.2s ease-in-out 0.2s"};
  }

  div {
    top: 0;
  }

  :after {
    top: 7px;
  }

  :before {
    top: 14px;
  }

  ${(props) =>
    props.isActive &&
    css`
      border: none;

      :before {
        top: 7px;
        transform: rotate(-45deg);
      }

      :after {
        display: none;
      }

      div {
        top: 7px;
        transform: rotate(45deg);
      }
    `}
`;

export const Menu = styled.div`
  width: 100%;
  min-height: 150px;
  top: 5.375rem;
  left: 0;
  background-color: ${colors.black};
  position: absolute;

  display: ${(props) => (props.isActive ? "flex" : "none")};
  flex-direction: column;
  z-index: 1;
`;

export const LiveStreamLink = styled.div`
  font-size: ${fontSize.large};
  color: ${colors.white};
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  width: 100%;
`;

export const Row = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  margin: 0.5rem auto;
  background-color: transparent;
  text-align: center;
  color: ${colors.white};
  font-size: ${fontSize.large};
  cursor: pointer;

  :last-child {
    margin: 0 auto 0.5rem;
  }
`;

export const MobileLanguageWrapper = styled.div`
  display: flex;
`;
