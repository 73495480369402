import React from "react";

import {
  RegistrationInputField,
  RegistrationInputSection,
} from "./RegistrationFormComponents";
import { ErrorMessage } from "./Form";

export const RegistrationInput = ({
  field: { onChange, value, ...field },
  form: { errors, touched },
  placeholder,
  key,
  type,
  maxLength,
  errorMessage,
}) => {
  const message = touched[field.name] && errors[field.name];

  return (
    <RegistrationInputSection>
      <RegistrationInputField
        name={field.name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...field}
        maxLength={maxLength}
        message={message}
      />

      {message && <ErrorMessage>{message}</ErrorMessage>}

      {errorMessage &&
        (field.name === "phoneNumber" || field.name === "email") && (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        )}
    </RegistrationInputSection>
  );
};
