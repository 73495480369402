import React from "react";

import { colors } from "../styles/variable";
import { facebookPageId } from "../apiBase";

export const FacebookMessenger = () => (
  <div
    className="fb-customerchat"
    attribution="install_email"
    page_id={facebookPageId}
    theme_color={colors.theme}
    logged_in_greeting="Dzień dobry, w czym możemy pomóc?"
    logged_out_greeting="Dzień dobry, w czym możemy pomóc?"
    greeting_dialog_display="hide"
  />
);
