import React from "react";
import { useHistory, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  clearState,
  setUserAuthStatus,
} from "../../core/authentication/actions";
import { LanguageInfo } from "./LanguageInfo";
import {
  HeaderWrapper,
  LogoWrapper,
  NavigationWrapper,
  RegisterButton,
  ButtonWrapper,
  LogoutWrapper,
  LinkWrapper,
} from "./HeaderComponents";
import { routes } from "../../statics/routes";
import { cookieService } from "../../services/index";
import { logoUrlSelector, themeColorSelector } from "../../core/cms/selectors";
import { cmsApiBaseUrl } from "../../apiBase";

const HeaderContainer = ({
  t,
  isAuth,
  setIsOpen,
  scroll,
  isCorrectPath,
  clearState,
  cms,
}) => {
  const history = useHistory();

  const handleClick = () =>
    isAuth ? history.push(routes.stream) : setIsOpen(true);

  const handleLogout = () => {
    setIsOpen(false);
    setUserAuthStatus(false);
    cookieService.removeItem("token");
    cookieService.removeItem("refresh");
    history.push(routes.main);
    clearState();
  };

  const logoUrl = logoUrlSelector(cms);

  return (
    <HeaderWrapper isActive={isCorrectPath}>
      <div>
        <Link to={routes.main}>
          {logoUrl && (
            <LogoWrapper
              src={`${cmsApiBaseUrl}${logoUrl}`}
              isActive={isCorrectPath}
            />
          )}
        </Link>
      </div>

      <NavigationWrapper>
        <LanguageInfo />
        <LinkWrapper onClick={() => history.push(routes.main)}>
          {t("event.home page")}
        </LinkWrapper>
        <LinkWrapper onClick={handleClick}>{t("event.button")}</LinkWrapper>
        <LinkWrapper onClick={scroll}>{t("partners")}</LinkWrapper>
        <div>
          {isAuth ? (
            <LogoutWrapper
              onClick={handleLogout}
              themeColor={themeColorSelector(cms)}
            >
              {t("logout")}
            </LogoutWrapper>
          ) : (
            <ButtonWrapper
              onClick={() => setIsOpen(true)}
              themeColor={themeColorSelector(cms)}
            >
              <RegisterButton themeColor={themeColorSelector(cms)}>
                {t("login")}
              </RegisterButton>
            </ButtonWrapper>
          )}
        </div>
      </NavigationWrapper>
    </HeaderWrapper>
  );
};

const mapStateToProps = ({ user: { userAuthStatus }, cms }) => ({
  isAuth: userAuthStatus,
  cms,
});

const mapDispatchToProps = {
  clearState,
  setUserAuthStatus,
};

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("home")(HeaderContainer));
