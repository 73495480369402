import React from "react";
import { withTranslation } from "react-i18next";
import { Formik, Field } from "formik";
import { connect } from "react-redux";

import { registerRequest } from "../../core/authentication/actions";
import {
  ErrorMessage,
  LinkSectionWrapper,
  LinkWrapper,
  HaveAccountInfo,
} from "./Form";
import * as S from "./RegistrationFormComponents";
import { RegistrationInput } from "./RegistrationInput";
import { Success } from "./Success";
import { themeColorSelector } from "../../core/cms/selectors";
import { Regulation } from "./Regulation";
import { registerSchema } from "./schema";
import { cookieService } from "../../services";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  repeatPassword: "",
  phoneNumber: "",
  agreedToSmsSubscription: false,
  dataProcessing: false,
  withdrawalOfConsent: false,
};

const RegisterFormComponent = ({
  t,
  handleActivity,
  isLoginActive,
  registerRequest,
  message,
  emailError,
  isRegisterComplete,
  cms,
  phoneNumberError,
}) => {
  const [emailMessage, setEmailMessage] = React.useState("");
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = React.useState(
    ""
  );
  const invitationCode = cookieService.getItem("invitationCode");
  const regulationContent = [...t("regulations", { returnObjects: true })];

  const input = [
    { type: "text", value: "firstName", name: "name", maxLength: 30 },
    { type: "text", value: "lastName", name: "surname", maxLength: 150 },
    {
      type: "tel",
      value: "phoneNumber",
      name: "phone number",
      errorMessage: phoneNumberErrorMessage,
    },
    {
      type: "email",
      value: "email",
      name: "email",
      maxLength: 254,
      errorMessage: emailMessage,
    },
    { type: "password", value: "password", name: "password" },
    { type: "password", value: "repeatPassword", name: "repeat password" },
  ];

  const regulationElements = [
    {
      content: regulationContent[0],
      required: false,
      fieldName: "agreedToSmsSubscription",
    },
    {
      content: regulationContent[1],
      required: true,
      fieldName: "dataProcessing",
    },
    {
      content: regulationContent[2],
      required: true,
      fieldName: "withdrawalOfConsent",
    },
  ];

  const checkPhoneNumber = (phoneNumber, agreedToSmsSubscription) => {
    if (phoneNumber === "") {
      return null;
    }
    return agreedToSmsSubscription ? phoneNumber : null;
  };

  const submitRegister = ({ email, ...values }) => {
    const registerData = {
      ...values,
      phoneNumber: checkPhoneNumber(
        values.phoneNumber,
        values.agreedToSmsSubscription
      ),
      email: email.toLowerCase(),
      invitationCode: invitationCode,
      agreedToEmailSubscription: values.agreedToSmsSubscription,
    };
    registerRequest(registerData);
  };

  React.useEffect(() => {
    emailError && setEmailMessage(t("errors.unique credentials"));
    phoneNumberError &&
      setPhoneNumberErrorMessage(t("errors.unique phone number"));
  }, [emailError, phoneNumberError]);

  return isRegisterComplete ? (
    <Success />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={registerSchema({ t })}
      onSubmit={(values) => submitRegister(values)}
    >
      {({ handleSubmit, errors, touched }) => (
        <S.RegistrationWrapper>
          <S.RegistrationFormWrapper onSubmit={handleSubmit}>
            <S.RegistrationTitleWrapper>
              {t("register title")}
            </S.RegistrationTitleWrapper>
            <S.InputsArea>
              {input.map((item) => (
                <Field
                  key={item.value}
                  name={item.value}
                  type={item.type}
                  placeholder={t(`user.${item.name}`)}
                  maxLength={item.maxLength}
                  component={RegistrationInput}
                  errorMessage={item.errorMessage}
                />
              ))}
            </S.InputsArea>
            {regulationElements.map((item) => (
              <Regulation
                errors={errors}
                touched={touched}
                data={item}
                key={item.fieldName}
              />
            ))}
            <S.RegistrationSubmitButton
              type="submit"
              themeColor={themeColorSelector(cms)}
            >
              {t("register button")}
            </S.RegistrationSubmitButton>
            <ErrorMessage>{message}</ErrorMessage>
            <LinkSectionWrapper>
              <HaveAccountInfo>{t("have account")}</HaveAccountInfo>
              <LinkWrapper onClick={() => handleActivity(!isLoginActive)}>
                {t("login button")}
              </LinkWrapper>
            </LinkSectionWrapper>
          </S.RegistrationFormWrapper>
        </S.RegistrationWrapper>
      )}
    </Formik>
  );
};

const mapStateToProps = ({
  user: { emailError, message, isRegisterComplete, phoneNumberError },
  cms,
}) => ({
  phoneNumberError,
  emailError,
  message,
  isRegisterComplete,
  cms,
});

const mapDispatchToProps = {
  registerRequest,
};

export const RegisterForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("auth")(RegisterFormComponent));
