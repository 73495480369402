export class EventOrdersFactory {
  constructor(apiUrl, httpService) {
    this.apiUrl = apiUrl;
    this.httpService = httpService;
  }

  getEventsList = () => this.httpService.get(`${this.apiUrl}/events/`, false);

  getEventPrices = (eventId) =>
    this.httpService.get(`${this.apiUrl}/events/${eventId}/tickets/`, false);

  makeOrder = (eventId, values) =>
    this.httpService.post(
      `${this.apiUrl}/events/${eventId}/orders/`,
      values,
      true
    );

  getOrders = (eventId) =>
    this.httpService.get(`${this.apiUrl}/events/${eventId}/orders/`, true);
}
